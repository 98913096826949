import React, { useState } from "react";
import { Banner } from "../../core/assets/resources/images";
import { WhyYouNeedInstanceCredit } from "../components/data/whyYouNeedIinstanceCredit";
import { OurNetwork } from "../components/data/ourNetwork";
import { CountryFlags } from "../components/data/countries";
import { classNames } from "../../core/utils/className";

export const Home = () => {
  const [hoveredKey, setHoveredKey] = useState<number | undefined>(undefined);

  return (
    <div className="block text-center mt-12 md:mt-32">
      <div className="text-2xl font-bold headerFont">
        Terminate funds to over
      </div>
      <div className=" text-4xl md:text-6xl lg:text-[96px] font-bold md:my-3 headerFont">
        30 African Countries
      </div>
      <div className="text-2xl font-bold headerFont">fast and securely.</div>

      <div className=" mt-12">
        <img
          src={Banner}
          className="md:w-full md:object-cover object-contain"
        />
      </div>

      <div>
        <div className="text-2xl font-bold mt-24 headerFont" id="our-network">Our Network</div>

        <div className="flex justify-center flex-wrap gap-5 my-8">
          {OurNetwork.map((items, key) => (
            <div className="our-network-background  transform transition duration-200 ease-in-out hover:scale-125">
              <div className=" w-full mt-[-15px]">
                <div className="font-bold text-3xl headerFont">
                  {items.figure}
                </div>
                <div className="text-[13px]">{items.text}</div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="w-full my-16">
        <div className="text-2xl my-8 font-bold headerFont">
          Operating Countries
        </div>
        <div className="flex gap-3 px-2 md:px-4 max-w-screen-lg w-full mx-auto flex-wrap  items-center ">
          {CountryFlags.map((item, key) => (
            <div key={key}>
              <img
                src={item.flag}
                className={classNames( key === 6 ? "rounded-full" : "w-[43.84px] h-[43.84px]",
                   " transform transition duration-200 ease-in-out hover:scale-125"
                  )}/>
            </div>
          ))}
        </div>
      </div>

      <div className="features-card  px-4 lg:px-16 " id="features">
        <div className=" px-8 xl:px-24 rounded-3xl  relative w-full items-center py-5 ">
          <div className="text-2xl font-bold text-center my-16 headerFont">
          The Instant Credit Advantage
          </div>
          <div
            className="grid grid-cols-1 bg-[#E0E0E0] gap-x-24 gap-y-3 p-12 lg:grid-cols-2 text-start items-center text-[13px]"
            style={{ borderRadius: "70px" }}
          >
            {WhyYouNeedInstanceCredit.map((item, key) => (
              <div
                className={`text-start bg-white py-12 px-6 group h-48 overflow-hidden transition-all duration-500 ease-in-out transform ${
                  hoveredKey === key
                    ? "scale-y-105 h-auto my-3 transition-all duration-900 ease-in-out transform"
                    : " h-32"
                }`}
                style={{ borderRadius: "40px" }}
                key={key}
                onMouseEnter={() => setHoveredKey(key)}
                onMouseLeave={() => setHoveredKey(undefined)}
              >
                <div className="flex gap-6 items-center">
                  <img src={item.icon} className="" alt="icon" />
                  <div >{item.text}</div>
                </div>
                <div className="hidden group-hover:block">{item.content}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
