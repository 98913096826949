import {
  Point1,
  Point2,
  Point3,
  Point4,
  Point6,
  Point7,
} from "../../../core/assets/resources/images";

export const WhyYouNeedInstanceCredit = [
  {
    icon: Point1,
    text: (
      <div className="text-start leading-4 text-[20px]">
        <div className="text-[15px] md:text-[20px]">Single API Integration</div>
      </div>
    ),
    content: (
      <div className="leading-4 my-4">
        With a single integration to Instant Credit, you gain access to multiple
        mobile money platforms and bank accounts across Africa. This streamlines
        the process for our partners, eliminating the need to establish and
        manage connections with multiple MNOs and banks across the continent.
      </div>
    ),
  },
  {
    icon: Point2,
    text: (
      <div className="text-start leading-4 text-[20px]">
        <div className="text-[15px] md:text-[20px]">Competitive FX Rates</div>
      </div>
    ),
    content: (
      <div className="leading-4 my-4">
        We recognize the importance of competitive FX rates in service delivery
        for IMTs, and we strive to offer the best rates for our partners across
        all markets.
      </div>
    ),
  },
  {
    icon: Point3,
    text: (
      <div className="text-start  leading-4">
        <div className="text-[15px] md:text-[20px] leading-6">
          <div>Credit lines and Flexible</div> <div>payment terms</div>
        </div>
      </div>
    ),
    content: (
      <div className="leading-4 my-4">
        We have the capacity to advance credit lines to partners while waiting
        for trades to clear with our settlement banks. Additionally, we are open
        to discussing mutually beneficial payment terms with all our partners.
      </div>
    ),
  },
  {
    icon: Point6,
    text: (
      <div className="text-start leading-4">
        <div className="text-[15px] md:text-[20px]">Daily Reconciliation</div>
      </div>
    ),
    content: (
      <div className="leading-4 my-4">
        Daily reconciliations are conducted for all bank and wallet
        transactions, with reports sent for all transactions each day.
      </div>
    ),
  },
  {
    icon: Point7,
    text: (
      <div className="text-start leading-4">
        <div className="text-[15px] md:text-[20px]">Transparency</div>
      </div>
    ),
    content: (
      <div className="leading-4 my-4">
        All transactions are fully transparent through the tracking portal,
        which allows you to track real-time updates of transactions and generate
        reports for reconciliation and settlements.

      </div>
    ),
  },
  {
    icon: Point4,
    text: (
      <div className="text-start leading-4">
        <div className="text-[15px] md:text-[20px]">Security</div>
      </div>
    ),
    content: (
      <div className="my-4 leading-4">
        The Instant Credit portal maintains audit logs to track usage and
        resolve disputes. Our portals are secured with VPN, HTTPS, and SSL
        certification, adhering to standard security protocols to protect
        transactions and processes. Transactions are monitored in real time,
        with set limits to reduce the risk of fraud and money laundering.
        Suspicious transactions are reported for further investigation.
      </div>
    ),
  },
];
